"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var _default = function _default(_ref) {
  var _ref$RouterProps = _ref.RouterProps,
      userRouterProps = _ref$RouterProps === void 0 ? {} : _ref$RouterProps;
  return {
    Router: function Router() {
      return function (_ref2) {
        var children = _ref2.children,
            basepath = _ref2.basepath,
            staticInfo = _ref2.staticInfo;
        var Router;
        var routerProps = {
          basepath: basepath // Required
          // Test for document to detect the node stage

        };

        if (typeof document !== 'undefined') {
          // If in the browser, just use the browser router
          Router = _reactRouterDom.BrowserRouter;
        } else {
          Router = _reactRouterDom.StaticRouter;
          routerProps.location = staticInfo.path; // Required

          routerProps.context = {}; // Required
        }

        return _react.default.createElement(Router, _extends({}, routerProps, userRouterProps), _react.default.createElement(_react.default.Fragment, null, children));
      };
    }
  };
};

exports.default = _default;